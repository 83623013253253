import React, {useState, useRef, useEffect} from 'react';
import {
    Menu,
    Heart,
    Plus,
    ChevronUp,
    ChevronDown,
    Settings,
    X,
    Minus,
    ArrowLeft,
    ChevronRight,
    ChevronLeft,
    Facebook, Instagram, Twitter, Youtube
} from 'lucide-react';
import {motion, AnimatePresence} from 'framer-motion';


const MENU_BG_COLOR = 'bg-amber-950';
const MENU_ACTIVE_COLOR = 'bg-yellow-400';


const Loader = () => (
    <motion.div
        className="fixed inset-0 bg-black flex flex-col items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
    >
        {/* Kalp Atışı Efekti */}
        <motion.div
            className="relative flex items-center justify-center w-40 h-40"
            animate={{
                scale: [1, 1.1, 1],
            }}
            transition={{
                duration: 0.5,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
            }}
        >
            {/* Logonuz */}
            <img
                src="/logo.png"  // Logo yolunuzu buraya ekleyin
                alt="Logo"
                className="w-20 h-20 object-contain relative z-10"
            />
        </motion.div>

        {/* Sade Başlık */}
        <motion.h2
            className="text-gray-100 text-2xl font-semibold mt-6"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
        >
            Loading...
        </motion.h2>
    </motion.div>
);



const WaffleMenu = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [activeCategory, setActiveCategory] = useState('Tatlı Waffle');
    const [isFullScreenMenuOpen, setIsFullScreenMenuOpen] = useState(false);
    const [isAtTop, setIsAtTop] = useState(true);
    const [currentPage, setCurrentPage] = useState('home');
    const categoriesRef = useRef(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const lastScrollPosition = useRef(0);

    const [showIntro, setShowIntro] = useState(true);

    const IntroPage = () => {
        return (
            <motion.div
                className="fixed inset-0 bg-black flex flex-col items-end justify-end z-50"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                {/* Video Background */}
                <div className="absolute inset-0">
                    <iframe
                        src="https://youtube.com/embed/m6kEbeUeXHI?autoplay=1&controls=0&mute=1&loop=1&playlist=m6kEbeUeXHI&showinfo=0&rel=0&modestbranding=1"
                        className="absolute w-full h-full object-cover pointer-events-none"
                        allow="autoplay; encrypted-media"
                        frameBorder="0"
                    />
                    {/*<div className="absolute inset-0 bg-black/60" />*/}
                </div>

                {/* Main Background SVG - Fills entire screen */}
                <div className="absolute inset-0">
                    <img
                        src="/arkaplan.svg"
                        alt="Background Pattern"
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Right Bottom Content Stack */}
                <div className="relative z-10 flex flex-col items-end p-8 space-y-4">
                    {/* Logo */}
                    <motion.div
                        initial={{opacity: 0, x: 20}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 0.8}}
                        className="flex items-center gap-2"
                    >
                        <img src="/logo.png" alt="" className="w-36"/>
                    </motion.div>


                    {/* Menu Button */}
                    <motion.button
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.6, duration: 0.5}}
                        onClick={() => setShowIntro(false)}
                        className="px-2 py-2 bg-red-600 text-white rounded text-xs font-xs
                    hover:bg-red-700 transition-colors duration-300 uppercase tracking-wider mt-2"
                        style={{
                            borderRadius: '4px',
                            backgroundColor: '#DC2626' // Tam olarak görseldeki kırmızı renk
                        }}
                    >
                        Menüyü Görüntüle
                    </motion.button>

                    {/* Social Media Icons */}



                    <motion.div
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.9, duration: 0.5}}
                        className="flex gap-4"
                    >
                        {[
                            {Icon: Facebook, href: "#"},
                            {Icon: Instagram, href: "#"},
                            {Icon: Twitter, href: "#"},
                            {Icon: Youtube, href: "#"},
                        ].map(({Icon, href}, index) => (
                            <motion.a
                                key={index}
                                href={href}
                                whileHover={{scale: 1.2}}
                                className="text-white hover:text-red-400 transition-colors duration-300"
                            >
                                <Icon size={28}/>
                            </motion.a>
                        ))}
                    </motion.div>

                    {/* SLOGAN */}
                    <motion.div
                        initial={{opacity: 0, x: 20}}
                        animate={{opacity: 1, x: 0}}
                        transition={{duration: 0.8}}
                        className="flex items-center gap-2"
                    >
                        <img
                            src="/slogan.svg"
                            alt=""
                            className="w-64 object-cover"
                        />
                    </motion.div>
                </div>
            </motion.div>
        );
    };

    const ImageCarousel = ({images}) => {
        const scrollContainer = useRef(null);
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        const scroll = (direction) => {
            if (scrollContainer.current) {
                const scrollAmount = direction === 'left' ? -400 : 400;
                scrollContainer.current.scrollBy({
                    left: scrollAmount,
                    behavior: 'smooth'
                });
            }
        };

        return (
            <div className="relative">
                <div
                    ref={scrollContainer}
                    className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
                    style={{scrollSnapType: 'x mandatory'}}
                >
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="flex-none w-full snap-center flex justify-center items-center"
                        >
                            <img
                                src={image}
                                alt={`Product ${index + 1}`}
                                className="w-64 h-64 object-cover"
                            />
                        </div>
                    ))}
                </div>

                <button
                    onClick={() => scroll('left')}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-100/50 rounded-full p-2 hover:bg-gray-100/80 transition-colors"
                >
                    <ChevronLeft size={24}/>
                </button>
                <button
                    onClick={() => scroll('right')}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-100/50 rounded-full p-2 hover:bg-gray-100/80 transition-colors"
                >
                    <ChevronRight size={24}/>
                </button>

                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`h-2 w-2 rounded-full ${
                                index === currentImageIndex ? 'bg-gray-100' : 'bg-gray-100/50'
                            }`}
                        />
                    ))}
                </div>
            </div>
        );

    };

    const menuItems = {
        'Tatlı Waffle': [
            {
                title: "Honey Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-400"
            },
            {
                title: "Maple Butter",
                price: "300",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-orange-400"
            },
            {
                title: "Çilek Reçelli",
                price: "320",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-300"
            },
            {
                title: "Nutella",
                price: "330",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-600"
            },
            {
                title: "Karamelli",
                price: "310",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-500"
            },
            {
                title: "Honey Butter",
                price: "300",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-400"
            },
            {
                title: "Maple Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-orange-400"
            },
            {
                title: "Çilek Reçelli",
                price: "320",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-300"
            },
            {
                title: "Nutella",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-600"
            },
            {
                title: "Çilekli",
                price: "340",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-500"
            },
            {
                title: "Muzlu",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-500"
            },
            {
                title: "Peynirli",
                price: "350",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-400"
            },
            {
                title: "Karışık",
                price: "400",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-pink-400"
            },
            {
                title: "Çilekli",
                price: "340",
                image: "/product/b.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-500"
            },
            {
                title: "Muzlu",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-500"
            }
        ],
        'Tuzlu Waffle': [
            {
                title: "Peynirli",
                price: "350",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-400"
            },
            {
                title: "Karışık",
                price: "400",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-pink-400"
            }
        ],
        'Çikolatalı': [
            {
                title: "Bitter Çikolata",
                price: "320",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-700"
            },
            {
                title: "Sütlü Çikolata",
                price: "320",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-600"
            }
        ],
        'Meyveli': [
            {
                title: "Çilekli",
                price: "340",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-500"
            },
            {
                title: "Muzlu",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-500"
            }
        ],
        'İçecekler': [
            {
                title: "Limonata",
                price: "100",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-green-400"
            },
            {
                title: "Smoothie",
                price: "150",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-emerald-400"
            }
        ],
        'Sıcak İçecekler': [
            {
                title: "Türk Kahvesi",
                price: "90",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-900"
            },
            {
                title: "Sıcak Çikolata",
                price: "120",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-800"
            }
        ],
        'Tatlılar': [
            {
                title: "Cheesecake",
                price: "200",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-purple-400"
            },
            {
                title: "Tiramisu",
                price: "250",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-indigo-400"
            }
        ],
        'Tatlı Waffle 2': [
            {
                title: "Honey Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-400"
            },
            {
                title: "Maple Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-orange-400"
            },
            {
                title: "Çilek Reçelli",
                price: "320",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-300"
            },
            {
                title: "Nutella",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-600"
            },
            {
                title: "Karamelli",
                price: "310",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-500"
            },
            {
                title: "Honey Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-yellow-400"
            },
            {
                title: "Maple Butter",
                price: "300",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-orange-400"
            },
            {
                title: "Çilek Reçelli",
                price: "320",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-red-300"
            },
            {
                title: "Nutella",
                price: "330",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-600"
            },
        ],
        'Dondurma': [
            {
                title: "Çilekli Dondurma",
                price: "150",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-pink-300"
            },
            {
                title: "Çikolatalı Dondurma",
                price: "150",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-amber-500"
            }
        ],
        'Ekstralar': [
            {
                title: "Ekstra Sos",
                price: "30",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-gray-400"
            },
            {
                title: "Ekstra Krema",
                price: "40",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-gray-300"
            }
        ],
        'Vegan': [
            {
                title: "Vegan Waffle",
                price: "350",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-green-500"
            },
            {
                title: "Vegan Sos",
                price: "50",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-green-400"
            }
        ],
        'Ekstralar2': [
            {
                title: "Ekstra Sos",
                price: "30",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-gray-400"
            },
            {
                title: "Ekstra Krema",
                price: "40",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-gray-300"
            }
        ],
        'Vegan2': [
            {
                title: "Vegan Waffle",
                price: "350",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-green-500"
            },
            {
                title: "Vegan Sos",
                price: "50",
                image: "/product/i.png",
                images: ["/product/i.png", "/product/b.png", "/product/i.png"],
                bgColor: "bg-green-400"
            }
        ],
    };

    const toggleFullScreenMenu = () => {
        setIsFullScreenMenuOpen(!isFullScreenMenuOpen);
    };

    const handleScroll = () => {
        if (categoriesRef.current) {
            setIsAtTop(categoriesRef.current.scrollTop === 0);
            lastScrollPosition.current = categoriesRef.current.scrollTop;
        }
    };

    useEffect(() => {
        if (categoriesRef.current) {
            categoriesRef.current.scrollTop = lastScrollPosition.current;
        }
    }, [activeCategory]);

    useEffect(() => {
        // Simüle edilmiş yükleme süresi
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToPosition = (position) => {
        if (categoriesRef.current) {
            categoriesRef.current.scrollTo({
                top: position,
                behavior: 'smooth'
            });
        }
    };


    const WaffleMenuItem = ({item}) => (
        <motion.div
            className={`relative mb-20 w-64 h-40 ${item.bgColor} rounded-3xl shadow-md shadow-gray-400`}
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.3}}
            whileHover={{scale: 1.05}}
            onClick={() => setSelectedProduct(item)}
        >
            <div className={`relative mb-20 w-64 h-40 ${item.bgColor} rounded-3xl shadow-md shadow-gray-400`}
                 onClick={() => setSelectedProduct(item)}>
                <div className="p-5">
                    <div className="flex justify-between items-start">
                        <div>
                            <h3 className="text-xl font-bold text-black mb-1">{item.title}</h3>
                            <p className="text-black font-semibold text-base">₺{item.price}</p>
                        </div>
                        <Heart className="text-black" size={24}/>
                    </div>
                    <div className="flex justify-center">
                        <img src={item.image} alt={item.title}
                             className="w-29 h-29 object-cover filter drop-shadow-2xl"/>
                    </div>
                </div>
                <div
                    className="absolute top-1/2 right-0 w-16 h-16 bg-gray-100 rounded-full transform translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                    <button
                        className={`w-8 h-8 ${item.bgColor} rounded-full flex items-center justify-center shadow-md shadow-gray-400`}>
                        <Plus className="text-white" size={20}/>
                    </button>
                </div>
            </div>
        </motion.div>
    );

    const ProductDetailView = () => {
        if (!selectedProduct) return null;

        return (
            <motion.div
                className={`fixed inset-0 ${selectedProduct.bgColor} z-50 flex flex-col`}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div className={`fixed inset-0 ${selectedProduct.bgColor} z-50 flex flex-col`}>
                    <TopNavbar2 onBack={() => setSelectedProduct(null)} title={selectedProduct.title}
                                bgcolor={selectedProduct.bgColor}/>
                    <div
                        className="bg-gray-100 p-6 absolute bottom-0 rounded-tl-3xl rounded-tr-3xl w-full flex flex-col mt-auto">
                        {/* Product Image */}
                        <div className="w-full flex justify-center -mt-36 mb-4 relative z-10">
                            <ImageCarousel images={selectedProduct.images}/>
                        </div>

                        {/* Product Info */}
                        <div className="space-y-4 flex-1">
                            <div className="flex justify-between items-center">
                                <h2 className="text-gray-800 text-xl font-medium">{selectedProduct.title}</h2>
                                <Heart className="text-gray-800" size={20}/>
                            </div>

                            {/* Rating */}
                            <div className="flex">
                                {[1, 2, 3, 4].map((star) => (
                                    <svg key={star} className="w-5 h-5 text-yellow-400 fill-current"
                                         viewBox="0 0 24 24">
                                        <path
                                            d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
                                    </svg>
                                ))}
                                <svg className="w-5 h-5 text-gray-400 fill-current" viewBox="0 0 24 24">
                                    <path
                                        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
                                </svg>
                            </div>

                            {/* Quantity and Price */}
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    <button
                                        className="w-8 h-8 border border-gray-400 rounded flex items-center justify-center">
                                        <Minus className="text-gray-800" size={16}/>
                                    </button>
                                    <span className="text-gray-800">1</span>
                                    <button
                                        className="w-8 h-8 border border-gray-400 rounded flex items-center justify-center">
                                        <Plus className="text-gray-800" size={16}/>
                                    </button>
                                </div>
                                <span className="text-gray-800 font-medium text-3xl">₺{selectedProduct.price}</span>
                            </div>

                            {/* Description */}
                            <p className="text-gray-500 text-sm">
                                Delicious and soft waffles with Maple syrup served warm. Light and crisp and soooo
                                flavorful!
                            </p>
                        </div>

                        {/* Bottom Buttons */}
                        <div className="mt-4 flex items-center space-x-4">
                            <button
                                className="w-12 h-12 border border-gray-400 rounded flex items-center justify-center">
                                <ArrowLeft className="text-gray-800" size={24}/>
                            </button>
                            <button className={`flex-1 ${selectedProduct.bgColor} py-3 rounded font-medium`}>
                                ORDER NOW
                            </button>
                        </div>
                    </div>

                </div>
            </motion.div>
        );
    };

    const CategoryButton = ({category, isActive, onClick}) => (

        <button
            onClick={() => {
                onClick();
                if (categoriesRef.current) {
                    lastScrollPosition.current = categoriesRef.current.scrollTop;
                }
            }}
            className={`w-full h-32 relative group overflow-visible ${
                isActive ? 'text-yellow-400' : 'text-gray-300 hover:text-gray-200'
            }`}
        >

            <div className="flex items-center justify-center h-full">
      <span className="transform -rotate-90 whitespace-nowrap text-xs font-medium origin-center">
        {category}
      </span>
            </div>
            {isActive && (
                <div
                    className="absolute top-1/2 right-0 w-6 h-7 bg-gray-100 rounded-l-full transform translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                    <div className={`w-2 h-2 ${MENU_ACTIVE_COLOR} rounded-full ml-[-8px]`}/>
                </div>
            )}
        </button>
    );

    const Navbar = () => {
        const isProductPage = currentPage === 'home' || currentPage === 'products';

        return (
            <div className={`fixed left-0 top-0 bottom-0 w-14 ${MENU_BG_COLOR} flex flex-col z-20 overflow-x-hidden`}>
                <div className="h-16 flex items-center justify-center">
                    <button onClick={toggleFullScreenMenu}
                            className="text-white hover:text-yellow-400 transition-colors">
                        <Menu size={28}/>
                    </button>
                </div>

                {isProductPage ? (
                    <>
                        <div
                            ref={categoriesRef}
                            className="flex-1 overflow-y-auto overflow-x-hidden scrollbar-hide"
                            onScroll={handleScroll}
                        >
                            <div className="py-2 w-full">
                                {Object.keys(menuItems).map((category) => (
                                    <CategoryButton
                                        key={category}
                                        category={category}
                                        isActive={activeCategory === category}
                                        onClick={() => setActiveCategory(category)}
                                    />
                                ))}
                            </div>
                        </div>
                        <button
                            onClick={() => scrollToPosition(isAtTop ? categoriesRef.current?.scrollHeight : 0)}
                            className="p-4 border-t border-amber-700 text-white hover:bg-amber-700 transition-colors"
                        >
                            {isAtTop ? <ChevronDown size={28}/> : <ChevronUp size={28}/>}
                        </button>
                    </>
                ) : (
                    <button
                        onClick={() => setCurrentPage('products')}
                        className="flex-1 flex items-center justify-center"
                    >
                    <span
                        className="transform -rotate-90 whitespace-nowrap text-xl font-medium text-white hover:text-yellow-400 transition-colors">
                        Menüyü Görüntüle
                    </span>
                    </button>
                )}
            </div>
        );
    };

    const TopNavbar = () => (
        <div className={`fixed top-0 left-14 right-0 h-16 bg-gray-100 flex items-center px-6 z-30`}>
            <h1 className="text-amber-950 text-2xl font-bold flex-grow text-center">Waffle Menu</h1>
            <button className="text-amber-950 hover:text-yellow-400 transition-colors">
                <Settings size={24}/>
            </button>
        </div>
    );


    const TopNavbar2 = ({onBack, title, bgcolor}) => (
        <div className={`fixed top-0 left-0 right-0 h-16 ${bgcolor} flex justify-between items-center px-6 z-10`}>
            {onBack ? (
                <button onClick={onBack} className="text-amber-950">
                    <ArrowLeft size={28}/>
                </button>
            ) : (
                <button onClick={() => setIsFullScreenMenuOpen(true)}>
                    <Menu className="text-amber-950" size={28}/>
                </button>
            )}
            <h1 className="text-amber-950 text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">{title}</h1>
            <button className="text-amber-950 hover:text-yellow-400 transition-colors">
                <Settings size={24}/>
            </button>
        </div>
    );

    const FullScreenMenu = () => {
        if (!isFullScreenMenuOpen) return null;

        const menuItems = [
            {name: 'Anasayfa', href: 'home'},
            {name: 'Ürünler', href: 'products'},
            {name: 'İletişim', href: 'contact'},
            {name: 'Galeri', href: 'gallery'},
            {name: 'Şubeler', href: 'branches'},
        ];

        return (
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
                    <button onClick={toggleFullScreenMenu} className="absolute top-4 right-4 text-white">
                        <X size={32}/>
                    </button>
                    <nav>
                        <ul className="space-y-6 text-center">
                            {menuItems.map((item, index) => (
                                <motion.li
                                    key={item.name}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{delay: index * 0.1}}
                                >
                                    <a
                                        href="#"
                                        className="text-white text-3xl hover:text-yellow-400 transition-colors"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentPage(item.href);
                                            toggleFullScreenMenu();
                                        }}
                                    >
                                        {item.name}
                                    </a>
                                </motion.li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </motion.div>
        );
    };

    const ContactPage = () => (
        <div className="p-8">
            <h2 className="text-3xl font-bold mb-6">İletişim</h2>
            <form className="space-y-4">
                <div>
                    <label htmlFor="name" className="block mb-1">Ad Soyad</label>
                    <input type="text" id="name" className="w-full p-2 border rounded"/>
                </div>
                <div>
                    <label htmlFor="email" className="block mb-1">E-posta</label>
                    <input type="email" id="email" className="w-full p-2 border rounded"/>
                </div>
                <div>
                    <label htmlFor="message" className="block mb-1">Mesaj</label>
                    <textarea id="message" rows="4" className="w-full p-2 border rounded"></textarea>
                </div>
                <button type="submit" className={`${MENU_BG_COLOR} text-white px-4 py-2 rounded`}>Gönder</button>
            </form>
        </div>
    );

    const GalleryPage = () => {
        const images = [
            "/api/placeholder/300/200",
            "/api/placeholder/300/200",
            "/api/placeholder/300/200",
            "/api/placeholder/300/200",
            "/api/placeholder/300/200",
            "/api/placeholder/300/200",
        ];

        return (
            <div className="p-8">
                <h2 className="text-3xl font-bold mb-6">Galeri</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {images.map((src, index) => (
                        <img key={index} src={src} alt={`Gallery image ${index + 1}`}
                             className="w-full h-48 object-cover rounded"/>
                    ))}
                </div>
            </div>
        );
    };

    const BranchesPage = () => {
        const branches = [
            {name: "Kadıköy Şubesi", address: "Caferağa Mah. Moda Cad. No:123, Kadıköy/İstanbul"},
            {name: "Beşiktaş Şubesi", address: "Sinanpaşa Mah. Ortabahçe Cad. No:45, Beşiktaş/İstanbul"},
            {name: "Bakırköy Şubesi", address: "Cevizlik Mah. İstanbul Cad. No:67, Bakırköy/İstanbul"},
        ];

        return (
            <div className="p-8">
                <h2 className="text-3xl font-bold mb-6">Şubelerimiz</h2>
                <div className="space-y-6">
                    {branches.map((branch, index) => (
                        <div key={index} className="border p-4 rounded">
                            <h3 className="text-xl font-semibold mb-2">{branch.name}</h3>
                            <p>{branch.address}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderPage = () => {
        switch (currentPage) {
            case 'home':
            case 'products':
                return (
                    <div className="p-8 overflow-y-auto h-[calc(100vh-4rem)]">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                            {menuItems[activeCategory].map((item, index) => (
                                <WaffleMenuItem
                                    key={index}
                                    item={item}
                                />
                            ))}
                        </div>
                    </div>
                );
            case 'contact':
                return <ContactPage/>;
            case 'gallery':
                return <GalleryPage/>;
            case 'branches':
                return <BranchesPage/>;
            default:
                return <div>Page not found</div>;
        }
    };




    return (
        <>
            <AnimatePresence>
                {isLoading && <Loader />}
            </AnimatePresence>
            <AnimatePresence>
                {!isLoading && showIntro && <IntroPage />}
            </AnimatePresence>
            {!isLoading && !showIntro && (
                <div className="flex bg-gray-100 min-h-screen">
                    <TopNavbar />
                    <Navbar />
                    <div className="flex-1 pl-14 pt-16">
                        {renderPage()}
                    </div>
                    <AnimatePresence>
                        {isFullScreenMenuOpen && <FullScreenMenu />}
                    </AnimatePresence>
                    <AnimatePresence>
                        {selectedProduct && <ProductDetailView />}
                    </AnimatePresence>
                </div>
            )}
        </>
    );

};

export default WaffleMenu;